import React from 'react'
import ReactDOM from 'react-dom'
import {HashRouter} from 'react-router-dom'
import axios from 'axios'
import {ConfigProvider} from 'antd'
import './index.css'
import App from './App'

ConfigProvider.config({
  prefixCls: 'biz-ant',
  iconPrefixCls: 'biz-anticon',
});

axios.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function(error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function(response) {
    response.data.status = 200
    // 对响应数据做点什么
    return response.data;
  },
  function(error) {
    // 对响应错误做点什么
    // 可以在这里处理401错误，并重定向到登录页面
    if (error.response && error.response.status === 401) {
      // 清除token和跳转到登录页面
      // sessionStorage.removeItem('token');
      // window.location.href = 'https://artai.com.cn';
    }
    return Promise.reject(error);
  }
);

// window.addEventListener('message',function(event){
//   localStorage.setItem('token', event.data.token)
//   if (event.data?.token) {
//     ReactDOM.render(
//       <HashRouter>
//         <ConfigProvider prefixCls='biz-ant' iconPrefixCls='biz-anticon' >
//           <App />
//         </ConfigProvider>
//       </HashRouter>,
//       document.getElementById('root')
//     )
//   }
// }, false);
ReactDOM.render(
  <HashRouter>
    <ConfigProvider prefixCls='biz-ant' iconPrefixCls='biz-anticon' >
      <App />
    </ConfigProvider>
  </HashRouter>,
  document.getElementById('root')
)