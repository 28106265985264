import { useEffect, useState, useRef } from 'react'
import { UploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import OSS from 'ali-oss'
import { Modal, Pagination, Radio, Button, Upload } from 'antd'
import { request, requestPost, transMediaList, getOssUploadTokenAPI, requestGetV2 } from './utils'
import MediaItem from './MediaItem'

const options = [
  { label: '全部', value: 'all' },
  { label: '视频', value: 'video' },
  { label: '音频', value: 'audio' },
  { label: '图片', value: 'image' },
]

const PAGE_SIZE = 20
let uploadTimeout = null;

function SearchMediaModal (props) {
  const { onSubmit, onClose, projectId } = props
  const [selectedMedia, setSelectedMedia] = useState([])
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [mediaType, setMediaType] = useState(options[0].value)
  const [status, setStatus] = useState('loading')
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [media, setMedia] = useState([])
  const temporaryFile = useRef([])

  const uploadImgList = useRef([])
  
  useEffect(() => {
    setStatus('loading')
    requestGetV2('listMediaBasicInfos', { // https://help.aliyun.com/document_detail/197964.html
      pageSize: PAGE_SIZE,
      pageNo: page,
      mediaType: mediaType, // 可填写 all, video, audio, image
      includeFileBasicInfo: true,
      status: 'Normal'
    }).then(res => {
      setStatus('done')
      setMedia(res.data.rows)
      setTotal(res.data.count)
    }).catch(() => {
      setStatus('error')
      setTotal(0)
    })
  }, [mediaType, page])

  const uploadElProps: UploadProps = {
    name: 'file',
    action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
    multiple: true,
    showUploadList: false,
    accept: 'image/*, video/*, audio/*',
    customRequest: (e) => {
      if (e.file.type.indexOf('video/') === 0 || e.file.type.indexOf('image/') === 0 || e.file.type.indexOf('audio/') === 0) {
        temporaryFile.current.push(e)
      }
      // console.log(temporaryFile)
      if (uploadTimeout) {
        clearTimeout(uploadTimeout)
      }
      uploadTimeout = setTimeout(async () => {
        await uploadStart()
      }, 300)
      return false
    },
  };

  async function uploadStart() {
    setStatus('loading')
    const files = [].concat(temporaryFile.current)
    // setTemporaryFile([])
    temporaryFile.current = []
    const ossClient = await getAliOssClient()

    uploadImgList.current = []

    files.forEach(async (m, i) => {
      const item = m.file

      const myDate = new Date()
      const imgType = item.name.split('.')
      let imgT = imgType[imgType.length - 1]
      imgT = imgT.toLowerCase()
      const imgName
          = myDate.getTime().toString()
          + parseInt(Math.random() * 1000000, 10).toString()
      const imgUrl = `${imgName}.${imgT}`
      // this.$set(item, 'percentage', 0)
      // // 自定义文件名路径，如果要上传到特定的目录，需要将前缀也带上
      // // 例如 fileName 为 '/a/b/c/test.txt'，会将 test.txt 上传到对应目录下
      const fileName = `/artai/videoTemplate/${imgUrl}`
      // 请求oss接口上传
      // 分片上传
      const result = await ossClient.put(fileName, item);

      uploadImgList.current.push({
        url: result.res.requestUrls[0],
        name: imgUrl,
        type: item.type
      })
      console.log(uploadImgList.current.length, files.length)
      if (uploadImgList.current.length === files.length)
        uploadResult()
      // ossClient.multipartUpload(fileName, item, {
      //   parallel: 4, // 并发数量，一般是 4
      //   partSize: 1048576, // 分片大小，一般是 1M，也就是 1024 * 1024
      //   progress: async (p, checkpoint) => { // 监听上传事件
      //     console.log(p, checkpoint)
      //   },
      //   timeout: 120000, // 2分钟超时
      // }).then((response) => {
      //   uploadImgList.current.push({
      //     url: response.res.requestUrls[0],
      //     name: imgUrl,
      //     type: item.type
      //   })
      //   console.log(uploadImgList.current.length, files.length)
      //   if (uploadImgList.current.length === files.length)
      //     uploadResult()
      // }).catch((error) => {
      // })
    })
  }

  function uploadResult() {
    uploadImgList.current.forEach(async (m, i) => {
      try {
        await requestPost('registerMediaInfo', {
          inputUrl: m.url,
          name: m.name,
          mediaType: m.type.split('/')[0]
        })
      }catch(e) {
        console.log(e)
      }
      if (i === uploadImgList.current.length - 1) {
        if (mediaType !== 'all') {
          handleMediaTypeChange({target: { value: 'all' }})
        } else if (page !== 1) {
          setPage(1)
        } else {
          requestGetV2('listMediaBasicInfos', { // https://help.aliyun.com/document_detail/197964.html
            pageSize: PAGE_SIZE,
            pageNo: page,
            mediaType: mediaType, // 可填写 all, video, audio, image
            includeFileBasicInfo: true,
            status: 'Normal'
          }).then(res => {
            setStatus('done')
            setMedia(res.data.rows)
            setTotal(res.data.count)
          }).catch(() => {
            setStatus('error')
            setTotal(0)
          })
        }
      }
    })

  }

  async function getAliOssClient() {
    const res = await getOssUploadTokenAPI()// 获取凭证
    const { data } = res
    const parseMast = {
      bucket: 'artai-study-video-record', // bucket名称
      region: 'oss-cn-beijing', // 地域
      accessKeyId: data.AccessKeyId,
      accessKeySecret: data.AccessKeySecret,
      stsToken: data.SecurityToken,
      expiration: data.Expiration, // 有效期
      refreshSTSToken: async () => { // 刷新临时访问凭证的回调，在上传大文件的时候会用到
        const info = await getOssUploadTokenAPI()
        return {
          accessKeyId: info.AccessKeyId,
          accessKeySecret: info.AccessKeySecret,
          stsToken: info.SecurityToken,
        }
      },
      // 刷新临时访问凭证的时间间隔，单位为毫秒，在过期前一分钟刷新
      // 过期时间是后端配的，这里配合后端把时间写死也可以，例如 15 分钟过期，10 分钟就可以刷新一次
      refreshSTSTokenInterval: new Date(data.Expiration) - new Date() - 1000 * 60,
    }
    return new OSS(parseMast) // 调用OSS依赖
  }

  const handleSubmit = async () => {
    setConfirmLoading(true)
    // 组装数据
    const valueObj = {}
    selectedMedia.forEach((item) => {
      const mediaType = item.mediaType
      const mediaId = item.mediaId
      if (!valueObj[mediaType]) {
        valueObj[mediaType] = mediaId
      } else {
        valueObj[mediaType] += `,${mediaId}`
      }
    })
    const res = await request('AddEditingProjectMaterials', { // https://help.aliyun.com/document_detail/209069.html
      ProjectId: projectId,
      MaterialMaps: JSON.stringify(valueObj)
    })
    setConfirmLoading(false)
    onSubmit(transMediaList(res.data.MediaInfos))
  }

  const handleMediaTypeChange = (e) => {
    setMediaType(e.target.value)
    setPage(1)
  }

  const handleClick = (item) => {
    const index = selectedMedia.findIndex(m => m.mediaId === item.mediaId)
    if (index > -1) {
      setSelectedMedia(
        selectedMedia.filter((_, i) => i !== index)
      )
    } else {
      setSelectedMedia([...selectedMedia, item])
    }
  }

  const selectedMediaIds = selectedMedia.map(m => m.mediaId)

  return (
    <Modal
      open
      title='选择媒资导入'
      onOk={handleSubmit}
      onCancel={onClose}
      width={720}
      okButtonProps={{ disabled: selectedMedia.length === 0 }}
      okText='导入'
      cancelText='取消'
      confirmLoading={confirmLoading}
    >
      <div className="flex justify-between" style={{ marginBottom: '20px', marginTop: '20px' }}>
        <Radio.Group
          options={options}
          onChange={handleMediaTypeChange}
          value={mediaType}
          optionType="button"
          buttonStyle="solid"
        />
        <div>
          <Upload {...uploadElProps}>
            <Button icon={<UploadOutlined />} type="primary" size='Large'>点击上传</Button>
          </Upload>
        </div>
      </div>
      {status === 'done' && (
        media.length
          ? (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {media.map(item => (
                <MediaItem
                  onClick={() => handleClick(item)}
                  selected={selectedMediaIds.indexOf(item.mediaId) > -1}
                  key={item.mediaId}
                  item={item}
                />
              ))}
            </div>
            )
          : (
            <div style={{ height: '615px', textAlign: 'center' }}>暂无数据</div>
            )
      )}
      {status === 'loading' && (
        <div style={{ height: '615px', textAlign: 'center' }}>加载中...</div>
      )}
      {status === 'error' && (
        <div style={{ color: 'red', height: '615px', textAlign: 'center' }}>加载出错</div>
      )}
      <Pagination
        style={{ textAlign: 'center' }}
        defaultPageSize={PAGE_SIZE}
        current={page}
        total={total}
        showSizeChanger={false}
        onChange={(p) => setPage(p)}
      />
    </Modal>
  )
}

export default SearchMediaModal
